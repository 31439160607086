// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./images/header.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "header.not-found {\n  background-color: #d8d8d8;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  min-height: 100vh;\n}\nheader.not-found h1 {\n  margin-bottom: 15px;\n}\nheader.not-found .description {\n  font-size: 20px;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/NotFound/styles.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,yDAAA;EACA,iBAAA;AADJ;AAGI;EACI,mBAAA;AADR;AAII;EACI,eAAA;EACA,kBAAA;AAFR","sourcesContent":["@import 'styles/mixins';\n\nheader.not-found {\n    background-color: #d8d8d8;\n    background-image: url('images/header.jpg');\n    min-height: 100vh;\n\n    h1 {\n        margin-bottom: 15px;\n    }\n\n    .description {\n        font-size: 20px;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
