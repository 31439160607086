import {Trans, useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import Diff from 'components/Time/Diff';
import Period from 'components/Time/Period';
import linkedInLogo from './images/social/linkedin.svg';
import gitHubLogo from './images/social/github.svg';
import keybaseLogo from './images/social/keybase.svg';
import envelopeLogo from './images/envelope.svg';
import phoneLogo from './images/phone.svg';
import './styles.scss';

export default function Portfolio() {
    const {t, i18n} = useTranslation('portfolio');

    return (
        <article className="portfolio">
            <header>
                <div className="avatar" />
                <h1>{t('app:name')}</h1>

                <div className="social row">
                    <a href="https://linkedin.com/in/rmartinezponce"><img src={linkedInLogo} alt="LinkedIn" /></a>
                    <a href="https://github.com/RaulMRT"><img src={gitHubLogo} alt="GitHub" /></a>
                </div>

        
                <div className="web"><a href="https://raulmartinez.tech">RaulMartinez.tech</a></div>
            </header>

            <section className="about">
                <h2>{t('about.title')}</h2>

                <Trans
                    t={t}
                    parent="p"
                    i18nKey="about.introduction.text"
                    components={[
                        <strong />,
                        <a href={t('about.introduction.url')} />, // eslint-disable-line
                    ]} />
                <Trans
                    t={t}
                    parent="p"
                    i18nKey="about.info1.text"
                    components={[
                        <strong>
                            <a href={t('about.info1.url')} /> {/* eslint-disable-line */}
                        </strong>
                    ]} />

                <Trans
                    t={t}
                    parent="p"
                    i18nKey="about.info2.text"
                    components={[
                        <strong>
                            <a href={t('about.info2.url')} /> {/* eslint-disable-line */}
                        </strong>
                    ]} />

                <Trans
                    t={t}
                    parent="p"
                    i18nKey="about.cave.text"
                    components={[
                        <strong>
                            <a href={t('about.cave.url')} /> {/* eslint-disable-line */}
                        </strong>
                    ]} />

                <Trans
                    t={t}
                    parent="p"
                    i18nKey="about.principles"
                    components={[
                        <strong />
                    ]} />

                <div className="values row">
                    {Object.entries(t<string, object>('about.values')).map((item: [string, string]) => (
                        <span key={item[0]} className="value">{item[1]}</span>
                    ))}
                </div>
            </section>

            <section className="jobs">
                <h2>{t('jobs.title')}</h2>

                <div className="column stretch">
                <div className="job">
                        <div className="column">
                            <div className="position">
                                <Trans
                                    t={t}
                                    i18nKey="jobs.cubepath"
                                    components={[
                                        <small />
                                    ]} />
                            </div>
                            <div className="organization paragraph">
                                <a href="https://cubepath.com">Cubepath</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2024, 9, 1);
                                    const end = DateTime.local();
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge">
                                                {start.toLocaleString({
                                                    year: 'numeric',
                                                    month: 'long'
                                                })} — {t('time:now')}
                                            </span>
                                            <span className="badge">
                                                <Diff duration={duration} />
                                            </span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo cubepath" />
                    </div>
                    <div className="job">
                        <div className="column">
                            <div className="position">
                                <Trans
                                    t={t}
                                    i18nKey="jobs.brutal"
                                    components={[
                                        <small />
                                    ]} />
                            </div>
                            <div className="organization paragraph">
                                <a href="https://brutal.systems">Brutalsys</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2020, 4, 1);
                                    const end = DateTime.local(2024, 9, 1);
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge"><Period start={start} end={end} /></span>
                                            <span className="badge"><Diff duration={duration} /></span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo brutal" />
                    </div>
                    <div className="job">
                        <div className="column">
                            <div className="position">
                                <Trans
                                    t={t}
                                    i18nKey="jobs.alv"
                                    components={[
                                        <em />
                                    ]} />
                            </div>
                            <div className="organization paragraph">
                                <a href="https://alvportaequipajes.es">ALVPortaequipajes</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2013, 5, 1);
                                    const end = DateTime.local(2020, 4, 1);
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge"><Period start={start} end={end} /></span>
                                            <span className="badge"><Diff duration={duration} /></span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo alv" />
                    </div>
                    
                </div>
            </section>

            <section className="projects">
                <h2>{t('projects.title')}</h2>
                <div className="column stretch">

                    <div className="project">
                        <div className="column">
                            <div className="position">{t('projects.cloud')}</div>
                            <div className="organization paragraph">
                                <a href="https://cubepath.com">Cubepath Cloud</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2024, 9, 1);
                                    const end = DateTime.local();
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge">
                                                {start.toLocaleString({
                                                    year: 'numeric',
                                                    month: 'long'
                                                })} — {t('time:now')}
                                            </span>
                                            <span className="badge">
                                                <Diff duration={duration} />
                                            </span>
                                            <span className="badge emphasized">Cubepath</span>
                                            <span className="badge emphasized1">Lead</span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo cubepath" />
                    </div>
                   
                    <div className="project">
                        <div className="column">
                            <div className="position">{t('projects.kubernetes')}</div>
                            <div className="organization paragraph">
                                <a href="https://www.newtral.es">Newtral</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2024, 4, 15);
                                    const end = DateTime.local(2024, 7, 15);
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge"><Period start={start} end={end} /></span>
                                            <span className="badge"><Diff duration={duration} /></span>
                                            <span className="badge emphasized">Brutalsys</span>
                                            <span className="badge emphasized1">Lead</span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo newtral" />
                    </div>

                    <div className="project">
                        <div className="column">
                            <div className="position">{t('projects.kubernetes')}</div>
                            <div className="organization paragraph">
                                <a href="https://vozpopuli.com">Vozpopuli</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2023, 12, 15);
                                    const end = DateTime.local(2024, 3, 15);
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge"><Period start={start} end={end} /></span>
                                            <span className="badge"><Diff duration={duration} /></span>
                                            <span className="badge emphasized">Brutalsys</span>
                                            <span className="badge emphasized1">Lead</span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo vozpopuli" />
                    </div>

                    <div className="project">
                        <div className="column">
                            <div className="position">{t('projects.klql')}</div>
                            <div className="organization paragraph">
                                <a href="https://queensleague.pro">Queensleague</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2023, 3, 1);
                                    const end = DateTime.local(2023, 5, 15);
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge"><Period start={start} end={end} /></span>
                                            <span className="badge"><Diff duration={duration} /></span>
                                            <span className="badge emphasized">Brutalsys</span>
                                            <span className="badge emphasized1">Lead</span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo queensleague" />
                    </div>

                    <div className="project">
                        <div className="column">
                            <div className="position">{t('projects.klql')}</div>
                            <div className="organization paragraph">
                                <a href="https://kingsleague.pro">Kingsleague</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2022, 10, 1);
                                    const end = DateTime.local(2022, 12, 15);
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge"><Period start={start} end={end} /></span>
                                            <span className="badge"><Diff duration={duration} /></span>
                                            <span className="badge emphasized">Brutalsys</span>
                                            <span className="badge emphasized1">Lead</span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo kingsleague" />
                    </div>

                    <div className="project">
                        <div className="column">
                            <div className="position">{t('projects.kubernetes-assist')}</div>
                            <div className="organization paragraph">
                                <a href="https://forocoches.com">ForoCoches</a>
                            </div>
                            {
                                (() => {
                                    const start = DateTime.local(2021, 3, 9);
                                    const end = DateTime.local(2021, 8, 18);
                                    const duration = end.diff(start);

                                    return (
                                        <div className="period">
                                            <span className="badge"><Period start={start} end={end} /></span>
                                            <span className="badge"><Diff duration={duration} /></span>
                                            <span className="badge emphasized">Brutalsys</span>
                                            <span className="badge emphasized1">Assist</span>
                                        </div>
                                    );
                                })()
                            }
                        </div>
                        <div className="logo forocoches" />
                    </div>
                </div>
            </section>

            <section className="skills">
                <h2><span>{t('skills.title')}</span></h2>

                <div className="first">
                    <div className="second">
                        <Trans
                            t={t}
                            parent="h3"
                            i18nKey="skills.devops"
                            components={[
                                <em />
                            ]} />
                        <div className="row">
                            {
                                (() => {
                                    const skills = [
                                        ['Kubernetes', '100'],
                                        ['CI/CD', '100'],
                                        ['Docker', '100'],
                                        ['Git', '100'],
                                        ['Ansible', '75'],
                                        ['Terraform', '50'],
                                        ['Jenkins', '50'],
                                        ['Gitlab', '75'],
                                    ];

                                    return skills.map((item: string[], key: number) => (
                                        <div className="skill" key={key}>
                                            <span>{item[0]}</span>
                                            <div className={`level-` + item[1]} />
                                        </div>
                                    ));
                                })()
                            }
                        </div>

                        <h3>{t('skills.systems_and_networking')}</h3>
                        <div className="row">
                            {
                                (() => {
                                    const skills = [
                                        ['Proxmox', '100'],
                                        ['OpenNebula', '50'],
                                        ['Cephadm', '75'],
                                    ];

                                    return skills.map((item: string[], key: number) => (
                                        <div className="skill" key={key}>
                                            <span>{item[0]}</span>
                                            <div className={`level-` + item[1]} />
                                        </div>
                                    ));
                                })()
                            }
                        </div>

                        <h3>{t('skills.databases')}</h3>
                        <div className="row">
                            {
                                (() => {
                                    const skills = [
                                        ['MySQL/Percona/MariaDB', '100'],
                                        ['PostgreSQL', '25'],
                                        ['MongoDB', '25'],
                                    ];

                                    return skills.map((item: string[], key: number) => (
                                        <div className="skill" key={key}>
                                            <span>{item[0]}</span>
                                            <div className={`level-` + item[1]} />
                                        </div>
                                    ));
                                })()
                            }
                        </div>
                    </div>

                    <div className="second">
                        <h3>{t('skills.cloud_providers')}</h3>
                        <div className="row">
                            {
                                (() => {
                                    const skills = [
                                        ['Amazon Web Services', '75'],
                                        ['Google Cloud', '50'],
                                        ['Hetzner Cloud', '75'],
                                    ];

                                    return skills.map((item: string[], key: number) => (
                                        <div className="skill" key={key}>
                                            <span>{item[0]}</span>
                                            <div className={`level-` + item[1]} />
                                        </div>
                                    ));
                                })()
                            }
                        </div>

                        <h3>{t('skills.cache')}</h3>
                        <div className="row">
                            {
                                (() => {
                                    const skills = [
                                        ['Varnish Cache', '100'],
                                        ['Memcached', '75'],
                                        ['Redis Cache', '75'],
                                        ['Cloudflare CDN', '100'],
                                    ];

                                    return skills.map((item: string[], key: number) => (
                                        <div className="skill" key={key}>
                                            <span>{item[0]}</span>
                                            <div className={`level-` + item[1]} />
                                        </div>
                                    ));
                                })()
                            }
                        </div>

                        <h3>{t('skills.monitoring')}</h3>
                        <div className="row">
                            {
                                (() => {
                                    const skills = [
                                        ['Grafana', '75'],
                                        ['Prometheus', '50'],
                                        ['Consul', '25'],
                                    ];

                                    return skills.map((item: string[], key: number) => (
                                        <div className="skill" key={key}>
                                            <span>{item[0]}</span>
                                            <div className={`level-` + item[1]} />
                                        </div>
                                    ));
                                })()
                            }
                        </div>

                        <Trans
                            t={t}
                            parent="h3"
                            i18nKey="skills.frameworks_and_environments"
                            components={[
                                <em />
                            ]} />
                        <div className="row">
                            {
                                (() => {
                                    const skills = [
                                        ['Elasticsearch', '75'],
                                        ['Nginx', '100'],
                                        ['PHP-FPM', '100'],
                                        ['Postfix', '75'],
                                        ['Bash Scripting', '100'],
                                        ['Python', '25'],
                                        ['Prestashop', '100'],
                                        ['Wordpress', '100'],
                                    ];

                                    return skills.map((item: string[], key: number) => (
                                        <div className="skill" key={key}>
                                            <span>{item[0]}</span>
                                            <div className={`level-` + item[1]} />
                                        </div>
                                    ));
                                })()
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="languages">
                <h2>{t('languages.title')}</h2>

                <div className="language ca" style={{order: i18n.languages[0] === 'ca' ? 1 : 2}}>
                    <div className="name">{t('languages.ca')}</div>
                    <div className="badge">C1</div>
                </div>
                <div className="language es" style={{order: i18n.languages[0] === 'es' ? 1 : 2}}>
                    <div className="name">{t('languages.es')}</div>
                </div>
                <div className="language en" style={{order: i18n.languages[0] === 'en' ? 1 : 2}}>
                    <div className="name">{t('languages.en')}</div>
                    <div className="badge">B2</div>
                </div>
            </section> */}

            {/*<section className="articles">
                <h2><span>{t('articles.title')}</span></h2>

                <Link to="/websocket">
                    <div className="article websocket">{t('websocket.title')}</div>
                </Link>
                <Link to="/bitcoin">
                    <div className="article bitcoin">{t('bitcoin.title')}</div>
                </Link>
            </section>*/}

            <section className="contact">
                <h2>{t('contact.title')}</h2>

                <a href="mailto:hola@raulmartinez.tech">
                    <div className="contact-method mail">
                        <img src={envelopeLogo} alt="" />
                        hola@raulmartinez.tech
                    </div>
                </a>
                <a href="https://linkedin.com/in/rmartinezponce">
                    <div className="contact-method linkedin">
                        <img src={linkedInLogo} alt="" />
                        @rmartinezponce
                    </div>
                </a>
            </section>
        </article>
    );
}
