import {Duration} from 'luxon';
import {useTranslation} from 'react-i18next';

interface DiffProps {
    duration: Duration
}

type DurationParts = ReturnType<Duration['toObject']>;

export default function Diff(props: DiffProps) {
    const {t} = useTranslation('time');

    const parts: DurationParts = props.duration.normalize().shiftTo('years', 'months', 'weeks').toObject();
    parts.weeks = Math.floor(parts.weeks || 0);

    return (
        <>{t(durationType(parts), parts as Record<string, unknown>)}</>
    );
}

function durationType(duration: DurationParts): string {
    if (duration.years && duration.years > 0) {
        return 'durations.years';
    }

    if (duration.months && duration.months > 0) {
        return 'durations.months';
    }

    return 'durations.weeks';
}